<template>
  <div class="ly-emotion">
    <slot></slot>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'Emotion',
  components: {},
  mounted() {
    const name = this.$el.innerHTML
    const list = ['惊讶', '流泪', '害羞', '闭嘴', '睡觉', '发怒', '酷', '抓狂', '吐', '偷笑',
      '疑问', '嘘', '晕', '衰', '拜拜', '猪', '爱心', '心碎', '抱抱', '强', '左哼哼', '右哼哼',
      '打哈欠', '鄙视', '委屈', '阴险', '亲亲', '可怜', '来', '不', '困', '流汗',
      '咒骂', '弱', '好的', '挖鼻子', '鼓掌', '爱你', '大哭', '蛋糕', '给力', '滚',
      '蜡烛', '礼物', '胜利']
    const index = list.indexOf(name)
    const imgHTML = `<img src="https://edu.zhulong.com/img/emoji/${index}.png">`
    this.$nextTick(() => {
      this.$el.innerHTML = imgHTML
    })
  }
}
</script>

<style scoped>
*{
  box-sizing: border-box;
}
.ly-emotion{
  display: inline-block;
  width: 60px;
  height: 60px;
}
.ly-static-emotion{
  width: 58px;
  height: 50px;
  display: inline-block;
}
</style>
