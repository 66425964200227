<template>
  <div>
    <div class="box">
      <!-- 顶部发帖按钮区域 -->
      <div class="box-top">
        <img src="@/static/blackback2@2x.png" alt @click="comeback()">
        <span class="button1" @click="addThreadFun">{{ btnText }}</span>
        <p class="title1">意见反馈</p>
      </div>
      <!-- 发帖输入区域 -->
      <div class="box-add">
        <input v-model="ipt" type="text" placeholder="输入标题" placeholder-color="#f2f2f2">
        <!-- 图片文字区域 -->
        <div v-show="imgfont && loading ==false" class="plch">进行图文编辑</div>
        <div
          class="textarea"
          contenteditable="true"
          :class="isEmoji == true ? '' : 'active3'"
          @keydown="spanFun()"
          @click="closeEmojiFun()"
        >
          <div ref="editDivForm" contenteditable="true"></div>
        </div>
      </div>

      <!-- 底部固定框 -->
      <div class="box-deep" :class="isEmoji == true ? 'active2' : ''">
        <div>
          <!-- 照片上传 -->
          <van-uploader :after-read="afterRead" :multiple="true" accept="image/*" :preview-image="false">
            <img src="@/static/photo@2x.png" alt>
          </van-uploader>
          <!-- 拍照 -->
          <van-uploader
            :after-read="cameraRead"
            capture="camera"
            class="camera"
            :preview-image="false"
          >
            <img src="@/static/xiangji.png" alt>
          </van-uploader>
          <!-- 表情 -->
          <img src="@/static/face@2x.png" alt @click.stop="emojiFun()">
        </div>
        <emotion v-show="isEmoji" ref="emotion" class="emoji" :height="90" @emotion="handleEmotion"></emotion>
      </div>
    </div>
    <van-popup v-model="show">请补全信息</van-popup>

    <!-- 遮罩等待 -->
    <loadingView v-show="loading" />
  </div>
</template>

<script>
import axios from 'axios'
import { postThread, attendGroup } from '@/api/regInfo.js'
import Emotion from './emotion/index.vue'
import { Toast } from 'vant'
import loadingView from '@/compontens/loadingView.vue'
import tool from '@/utils/tools.js'
import Cookie from 'js-cookie'

export default {
  components: {
    Emotion,
    loadingView
  },
  data() {
    return {
      // 自定义div双向绑定
      // 标签名
      tagLists: ['提问', '讨论', '资料', '分享'],
      indexs: -1,
      isEmoji: false,
      show: false,
      // 自定义提示
      imgfont: true,
      // 选中标签内容
      tag: '',
      // 输入框内容
      ipt: '',
      // 表情内容
      emojis: '',
      // 图片内容
      img: '',
      pohtos: [],
      // html内容
      msg: '',
      // gid: 201801
      gid: this.$route.query.gid || '856',
      tid: this.$route.query.tid || '',
      is_postSuccess: true,
      loading: false,
      btnText: '提交'
    }
  },
  watch: {
    emojis(value) {
      if (value !== '') {
        this.imgfont = false
      }
    },
    img(value) {
      if (value !== '') {
        this.imgfont = false
      }
    }
  },
  created() {
    // 调关注小组的接口
    attendGroup({ agency_id: Cookie.get('agency_id') || '2', group_id: this.gid }).then(res => {
      console.log(res)
    })
  },
  methods: {
    // 点击选择标签
    tagsFun(i, t) {
      this.indexs = t
      this.tag = i
    },
    spanFun() {
      this.imgfont = false
    },
    // 上传图片
    afterRead(e) {
      var fd = new FormData()
      if (e instanceof Array && e.length) {
        // 判断是否是多图上传 多图则循环添加进去
        e.forEach((item) => {
          fd.append('files[]', item.file) // 第一个参数字符串可以填任意命名，第二个根据对象属性来找到file
        })
      } else {
        fd.append('files[]', e.file)
      }
      this.upArr = fd.getAll('files[]')
      this.loading = true
      axios
        .post('https://f.zhulong.com/uploadZhulongImgForEditMuli.php', fd)
        .then((res) => {
          if (res.data.errNo && res.data.errNo == '100') {
            this.loading = false
            Toast.fail('图片格式不正确')
          } else {
            var pic_arr = res.data
            this.pohtos = []
            pic_arr.forEach((item) => {
              this.pohtos.push(item.url)
            })
            this.img = this.pohtos.join(' ')
            this.$refs.editDivForm.innerHTML += `
            <div>
            ${this.pohtos.map(i => `
              <img src="${i}" style="width:80%;display:block;margin-bottom:10px;">
            `).join('')}
            </div>
            `
            this.loading = false
          }
        })
    },
    // 打开相机
    cameraRead(file) {
      var formdata = new FormData()
      formdata.append('files', file.file)
      axios
        .post('https://f.zhulong.com/uploadZhulongImgForReal.php', formdata)
        .then((res) => {
          this.img = res.data.url
          this.$refs.editDivForm.innerHTML += `<img src="${this.img}" alt="" style="width:80%;display:block;margin-bottom:10px;">`
        })
    },
    // 表情开关
    emojiFun() {
      this.isEmoji = !this.isEmoji
    },
    closeEmojiFun() {
      this.isEmoji = false
    },
    // 选择表情
    handleEmotion(i) {
      this.emojis += i
      this.$refs.editDivForm.innerHTML += `<span>${i}</span>`
    },
    // 发帖
    async addThreadFun() {
      this.msg = document.getElementsByClassName('textarea')[0].innerHTML
      if (this.ipt === '' || this.msg === '') {
        this.show = true
        return
      }
      if (this.is_postSuccess === false) {
        return Toast('正在发帖中..')
      }
      // this.$toast('第一次点击')
      const title = '【AI建筑问答】' + this.ipt
      const dataInfo = {
        gid: this.gid,
        tid: this.tid,
        title: title,
        message: this.msg,
        genre: this.tag,
        filetype: this.formdata
      }
      this.is_postSuccess = false
      postThread(dataInfo).then(res => {
        if (res.errNo !== 0) {
          this.is_postSuccess = true
          Toast.fail(res.msg)
        } else {
          this.is_postSuccess = true
          Toast.success('发帖成功')
          this.comeback()
        }
      }).catch(() => {
        this.is_postSuccess = true
      })
    },
    // 返回上一页
    comeback() {
      console.log('shabi back')
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          window.WebViewJavascriptBridge.callHandler(
            'vue_backApp'
            , '返回'
            , function(responseData) {
              // android 返参数接受
            }
          )
        }
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.active {
  box-sizing: border-box;
  color: #ee2e2e !important;
  border: 2px solid #ee2e2e !important;
  background: #ffefef !important;
}
.active2 {
  height: 400px !important;
}
.active3 {
  min-height: 66vh !important;
}
.van-popup--center {
  width: 50%;
  height: 100px;
  text-align: center;
  border-radius: 10px;
  line-height: 100px;
}
/deep/.van-overlay {
  background-color: rgba(25, 25, 25, 0.5) !important;
}
.box {
  width: 100%;
}
// 顶部发帖按钮区域
.box-top {
  width: 100%;
  height: 84px;
  padding: 0 34px;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .title1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    line-height: 84px;
    font-size: 28px;
    font-weight: 600;
    z-index: 0;
  }
  img {
    width: 42px;
    height: 42px;
    z-index: 10;
  }
  .button1 {
    padding: 0 25px;
    height: 54px;
    color: #fff;
    background: #ee2e2e;
    border-radius: 4px;
    text-align: center;
    line-height: 54px;
    font-size: 28px;
    z-index: 10;
  }
}
// 发帖内容区域
.box-main {
  width: 100%;
  padding: 0 34px;
  box-sizing: border-box;
  margin-top: 46px;
  // margin-bottom: 44px;
  // 内容区标签
  .tagtitle {
    display: inline-block;
    font-size: 30px;
    color: #666;
    margin-right: 42px;
    font-weight: 500;
  }
  .tags {
    display: inline-block;
    width: 113px;
    height: 48px;
    color: #666;
    border: none;
    font-size: 25px;
    box-sizing: border-box;
    background: #f2f2f2;
    border-radius: 37px;
    text-align: center;
    line-height: 50px;
    margin: 0 8px;
  }
}
// 发帖输入区域
.box-add {
  width: 100%;
  padding: 0 34px;
  box-sizing: border-box;
  margin-top: 44px;
  margin-bottom: 191px;
  height: 60px;
  input {
    width: 100%;
    font-size: 38px;
    font-weight: 550;
    padding: 0 8px;
    height: 60px;
    line-height: 50px;
    display: block;
    border: none;
    outline: none;
    caret-color: #ee2e2e;
    margin-bottom: 30px;
  }
  input::placeholder {
    font-family: Arial;
    letter-spacing: 1px;
    font-weight: 600;
    color: rgb(112, 109, 109) !important;
  }
  .textarea {
    width: 100%;
    font-size: 30px;
    border: none;
    outline: none;
    margin-top: -5px;
    padding: 0px 8px 250px;
    line-height: 45px;
    caret-color: #ee2e2e;
    min-height: 52vh;
    max-height: 70vh;
    overflow-y: scroll;
    z-index: 10;
    /deep/ img {
      text-align: center;
      max-width: 90%;
    }
  }
}
.plch {
  font-size: 30px;
  margin-top: -30px;
  color: #999;
  z-index: -100;
  position: relative;
  top: 30px;
  left: 10px;
}
//底部固定框
.box-deep {
  width: 100%;
  height: 88px;
  box-sizing: border-box;
  background: #f9f9f9;
  border-top: 2px solid #e2e2e2;
  position: fixed;
  bottom: 0;
  padding: 20px 34px;
  div {
    width: 338px;
    display: flex;
    align-items: center;
    img:nth-child(1) {
      width: 43px;
      height: 37px;
    }
    .camera {
      width: 43px;
      margin-left: 87px;
      position: relative;
      img {
        width: 43px;
        height: 43px;
      }
    }
    img:nth-child(3) {
      height: 43px;
      margin-left: 87px;
    }
  }
}
// 上传组件
/deep/.van-uploader {
  width: 43px !important;
  height: 37px !important;
  margin-left: 21px;
}
//表情
.emoji {
  margin-left: -10px !important;
  padding: 0 10px;
  width: 100% !important;
  margin-top: 20px;
}
.emoji::before {
  content: "";
  position: absolute;
  top: 88px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #e2e2e2;
}
</style>
